<template>
  <div>
    <v-text-field
      v-model="search"
      append-icon="search"
      label="Søk"
      single-line
      hide-details
    >
    </v-text-field>
    <v-spacer></v-spacer>
    <v-data-table
      :headers="headers"
      :items="log_entries"
      :search="search"
      :items-per-page="25"
      class="elevation-1"
    >
      <template v-slot:no-data> Loggen er tom </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.navn }}</td>
          <td>{{ item.a_nummer | formatPhonenumber }}</td>
          <td>{{ item.b_nummer }}</td>
          <td>{{ item.start | formatDateTime }}</td>
          <td>{{ item.slutt | formatDateTime }}</td>
          <td>{{ item.varighet | seconds2minutes }}</td>
          <td>{{ item.tilgang }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { formatNumber } from "libphonenumber-js";
import { readLog } from "../../utils/backend-api.js";

export default {
  components: {},
  data() {
    return {
      headers: [
        { text: "Navn", value: "navn" },
        { text: "Ringt fra", value: "a_nummer" },
        { text: "Ringt til", value: "b_nummer" },
        { text: "Start", value: "start" },
        { text: "Slutt", value: "slutt" },
        { text: "Varighet (min)", value: "varighet" },
        { text: "Tilgang", value: "tilgang" },
      ],
      log_entries: [],
      search: "",
    };
  },
  filters: {
    formatPhonenumber(value) {
      value = "+" + value;
      value = formatNumber(value, "International");
      return value;
    },
    formatDateTime: function (value) {
      const thisDate = new Date(value);
      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      return new Intl.DateTimeFormat("nb-NO", options).format(thisDate);
    },
    seconds2minutes: function (value) {
      return (value / 60).toFixed(1);
    },
  },
  methods: {
    async getLog() {
      try {
        const response = await readLog();
        this.log_entries = response.data;
      } catch (error) {
        // TODO: catch erros
      }
    },
  },

  created() {
    this.getLog();
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
