<template>
  <div class="log">
    <HeaderBox header="Statistikk" helpkey="statistics" />
    <StatsTable />
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import StatsTable from "../components/StatsTable.vue";

export default {
  data() {
    return {};
  },

  components: {
    HeaderBox,
    StatsTable,
  },
};
</script>
