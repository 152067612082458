import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

const ACCESS_TOKEN_KEY = "access_token";
const BACKEND_URL = process.env.VUE_APP_BACKEND_URL || "";

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

Vue.use(Vuex);

export function encodeConferenceName(conferenceName) {
  let newName = conferenceName;
  newName = newName.replace("æ", "e");
  newName = newName.replace("ø", "o");
  newName = newName.replace("å", "a");
  newName = newName.replace("ä", "e");
  newName = newName.replace("ö", "o");
  return newName;
}

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  isLoggedIn: sessionStorage.getItem(ACCESS_TOKEN_KEY) == null ? false : true,
  loginError: "",
  username: "",
  userid: 0,
  phonenumber: "",
  conference: "",
  conference_password: "",
  conference_max_channels: 0,
  country_code: "",
  samlinks: [],
  samlinkWantedState: {},
  samlinkState: {},
  samlinkAliveness: {},
  socket: {
    isConnected: false,
    message: "",
    reconnectError: false,
  },
  vueconfig: {
    showMenu: true,
  },
};

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  SET_LOGIN_STATE(state, isLoggedIn) {
    state.isLoggedIn = isLoggedIn;
  },
  SET_LOGIN_ERROR(state, error) {
    state.loginError = error;
  },
  SET_LOGIN_USERNAME(state, username) {
    state.username = username;
  },
  SET_USERID(state, userid) {
    state.userid = userid;
  },
  SET_PHONENUMBER(state, phonenumber) {
    state.phonenumber = phonenumber;
  },
  SET_CONFERENCE(state, conference) {
    state.conference = conference;
  },
  SET_CONFERENCE_PASSWORD(state, conference_password) {
    state.conference_password = conference_password;
  },
  SET_CONFERENCE_MAX_CHANNELS(state, conference_max_channels) {
    state.conference_max_channels = conference_max_channels;
  },
  SET_COUNTRY_CODE(state, country_code) {
    state.country_code = country_code;
  },
  SET_SAMLINKS(state, samlinks) {
    state.samlinks = samlinks;
  },
  SET_SAMLINK_STATE(state, { samlinkId, status, time }) {
    // lookup samlink and update state
    Vue.set(
      state.samlinks.find((s) => s.key === samlinkId),
      "reported_state",
      { time, state: status }
    );
  },

  SET_SAMLINK_WANTED_STATE(state, { samlinkId, status }) {
    console.log(state.samlinks, samlinkId);
    console.log(state.samlinks.find((s) => s.key === samlinkId));
    state.samlinks.find((s) => s.key === samlinkId).wanted_state = status;

    //Vue.set(state.samlinkWantedState, samlinkId, status);
  },
  SET_SAMLINK_ALIVENESS(state, { samlinkId, ping }) {
    Vue.set(state.samlinkAliveness, samlinkId, { lastSeen: ping });
  },
  SOCKET_ONOPEN(state, event) {
    console.log(event);
    Vue.prototype.$socket = event.currentTarget;
    state.socket.isConnected = true;
    console.log("onopen");
  },
  SOCKET_ONCLOSE(state) {
    state.socket.isConnected = false;
  },
  SOCKET_ONERROR(state, event) {
    console.log("onerror");
    console.error(state, event);
  },
  // default handler called for all methods
  SOCKET_ONMESSAGE(state, message) {
    console.log("onmessage");
    console.log("SOCKET ON MESSAGE:", message);
    state.socket.message = message;
  },
  // mutations for reconnect methods
  SOCKET_RECONNECT(state, count) {
    console.log("reconnect");
    console.info(state, count);
  },
  SOCKET_RECONNECT_ERROR(state) {
    console.log("reconnect error");
    state.socket.reconnectError = true;
  },
  VUE_MENU_ONOFF_SWITCH(state) {
    state.vueconfig.showMenu = !state.vueconfig.showMenu;
  },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  off: (device, sdad) => {
    console.log("off", device, sdad);
  },

  resetSamlinkState: ({ commit }, { samlinkId }) => {
    commit("SET_SAMLINK_STATE", {
      samlinkId: samlinkId,
      state: "off",
      time: -1,
    });
    Vue.prototype.$socket.sendObj({
      type: "reset",
      action: "reset",
      samlink_id: samlinkId,
    });
  },

  login: ({ commit }, { username, password }) => {
    const session_url = `${BACKEND_URL}/api/v1/session/`;
    axios.get(session_url).then(() => {
      const url = `${BACKEND_URL}/api/v1/sessionauth/`;

      axios
        .post(url, { username: username, password: password })
        .then((loginResponse) => {
          console.log("loginResponse: ", loginResponse);
          let authenticated = loginResponse.data.authenticated;
          if (authenticated) {
            commit("SET_LOGIN_STATE");
            commit("SET_CONFERENCE", loginResponse.data.conference);
            commit(
              "SET_CONFERENCE_PASSWORD",
              loginResponse.data.conference_password
            );
            commit(
              "SET_CONFERENCE_MAX_CHANNELS",
              loginResponse.data.conference_max_channels
            );
            commit("SET_COUNTRY_CODE", loginResponse.data.country_code);
            commit("SET_USERID", loginResponse.data.userid);
            commit("SET_PHONENUMBER", loginResponse.data.phonenumber);
            commit("SET_LOGIN_USERNAME", username);
            commit("SET_LOGIN_ERROR", "");
            window.vm.$connect(
              (window.location.protocol === "https:" ? "wss://" : "ws://") +
                window.location.host +
                "/ws/web"
            );
          } else {
            commit("SET_LOGIN_ERROR", "Feil brukernavn eller passord.");
            console.log("NOT AUTHENTICATED");
          }
        })
        .catch((e) => {
          console.log("ERROR: ", e);
          commit("SET_LOGIN_ERROR", e);
        });
    });
  },

  syncSession: ({ commit }) => {
    const session_url = `${BACKEND_URL}/api/v1/session/`;
    axios.get(session_url).then((response) => {
      console.log("response: ", response);
      axios.defaults.headers.common["Authorization"] = ""; // NOTE: Required unless you want to spend 30 hours on debugging
      axios.defaults.headers.post["X-CSRF-Token"] = response.data._csrf;

      if (response.data.authenticated) {
        commit("SET_LOGIN_STATE", true);
        commit("SET_CONFERENCE", response.data.conference);
        commit("SET_CONFERENCE_PASSWORD", response.data.conference_password);
        commit(
          "SET_CONFERENCE_MAX_CHANNELS",
          response.data.conference_max_channels
        );
        commit("SET_COUNTRY_CODE", response.data.country_code);
        commit("SET_USERID", response.data.userid);
        commit("SET_PHONENUMBER", response.data.phonenumber);
        commit("SET_LOGIN_USERNAME", response.data.user);
        commit("SET_LOGIN_ERROR", "");
        window.vm.$connect(
          (window.location.protocol === "https:" ? "wss://" : "ws://") +
            window.location.host +
            "/ws/web"
        );
      } else {
        commit("SET_LOGIN_STATE", false);
      }
    });
  },

  logout: ({ commit }) => {
    const url = `${BACKEND_URL}/accounts/logout/`;
    axios.post(url, {}).then(() => {
      commit("SET_LOGIN_STATE", false);
      window.vm.$disconnect();
    });
  },

  getSamlinks: ({ commit }) => {
    const url = `${BACKEND_URL}/api/v1/samlinks`;

    axios
      .get(url)
      .then((response) => {
        console.log("set samlinks response.data", response.data);
        commit("SET_SAMLINKS", response.data);
      })
      .catch(() => {
        commit("SET_LOGIN_STATE", false);
      });
  },

  increment: ({ commit }) => commit("increment"),
  decrement: ({ commit }) => commit("decrement"),
  incrementIfOdd({ commit, state }) {
    if ((state.count + 1) % 2 === 0) {
      commit("increment");
    }
  },
  incrementAsync({ commit }) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commit("increment");
        resolve();
      }, 1000);
    });
  },
};

// getters are functions
const getters = {};

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});
