import axios from "axios";

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL || "";

export function readEvents() {
  const url = `${BACKEND_URL}/api/v1/events/`;
  return axios.get(url).then((response) => response.data);
}

export function createEvent(newEvent) {
  const url = `${BACKEND_URL}/api/v1/events/`;
  return axios.post(url, newEvent);
}

export function updateEvent(thisEvent) {
  // TODO: Skal bare kunne endre info i event, ellers skal det være endring av datoer
  // TODO: I så fall fikse i API og endre fra PUT til PATCH.
  const url = `${BACKEND_URL}/api/v1/events/${thisEvent.id}/`;
  return axios.put(url, thisEvent);
}

export function deleteEvent(thisEvent) {
  const url = `${BACKEND_URL}/api/v1/events/${thisEvent.id}`;
  return axios.delete(url);
}

export function readSamlinks() {
  const url = `${BACKEND_URL}/api/v1/samlinks/`;
  return axios.get(url).then((response) => response.data);
}
