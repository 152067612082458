<template>
  <div class="log">
    <h1 class="headline">Websockets Test-page</h1>
    <p>Please note: check browser console to see output.</p>
    <p v-if="isConnected">We're connected to the server!</p>
    <p>
      Last message from server: <b>{{ socketMessage }}</b>
    </p>

    <h2>Test websockets</h2>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
        <tr>
          <td>Send message on websocket and get status</td>
          <td>
            <PingButton/>
          </td>
        </tr>
        <tr>
          <td>Start/stop "onmessage" (fixme)</td>
          <td>
            <StartListenButton/>
          </td>
          <td>
            <StopListenButton/>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

    <h2>Test Samlinks in your own conference</h2>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Samlink</th>
          <th class="text-left">Send</th>
          <th class="text-left">Receive</th>
          <th class="text-left">Stop</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="samlink in samlinks" :key="samlink.key">
          <td>{{ samlink.key }}</td>

        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import PingButton from '@/components/WebsocketPingButton'
import StartListenButton from '@/components/WebsocketStartListenButton'
import StopListenButton from '@/components/WebsocketStopListenButton'

export default {
  components: {
    PingButton,
    StartListenButton,
    StopListenButton,

  },

  computed: {
    isConnected() {
      return this.$store.state.socket.isConnected
    },

    socketMessage() {
      return this.$store.state.socket.message['message']
    },
    samlinks() {
      return this.$store.state.samlinks
    },
  },
  beforeCreate() {
    this.$store.dispatch('getSamlinks')
  },
}
</script>
