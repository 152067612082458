<template>
  <v-card class="pa-2" tile>
    <v-card-title> Konferanser </v-card-title>
    <v-card-text>
      <table>
        <tbody>
          <tr>
            <td><b>Telefon</b></td>
            <td><b>Forsamling</b></td>
          </tr>
          <tr v-for="i in conferencePhones" :key="i.msisdn">
            <td>{{ i.msisdn }}</td>
            <td>{{ i.konferanse }}</td>
          </tr>
        </tbody>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import { readConferencePhones } from "../../utils/backend-api.js";
export default {
  data() {
    return {
      conferencePhones: [],
    };
  },
  methods: {
    async getConferencePhones() {
      try {
        const response = await readConferencePhones();
        this.conferencePhones = response.sort(function (a, b) {
          return parseFloat(a.msisdn) - parseFloat(b.msisdn);
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getConferencePhones();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
