<template>
  <div>
    <h1 class="text-h5">
      {{ header }}
    </h1>
    <p>
      {{ helptext }}
    </p>
  </div>
</template>

<script>
import { readHelpItem } from "../../utils/backend-api.js";

export default {
  props: {
    header: String,
    helpkey: String,
  },

  data() {
    return {
      helptext: "",
    };
  },

  methods: {
    async getHelpItem() {
      try {
        const response = await readHelpItem(this.helpkey);
        this.helptext = response.data["tekst"];
      } catch (error) {
        // TODO: catch errors
      }
    },
  },

  created() {
    this.getHelpItem();
  },
};
</script>
