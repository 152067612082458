<template>
  <v-container fluid>
    <v-layout row>
      <v-flex>
        <innstillinger-change-form
          v-bind:userinfo="innstillinger"
        ></innstillinger-change-form>

        <HeaderBox header="Innstillinger" helpkey="innstillinger" />

        <v-container fluid class="pa-0 mx-0 my-4">
          <v-row>
            <v-col cols="12" sm="6">
              <v-card>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Brukernavn</v-list-item-subtitle>
                    <v-list-item-title>{{ username }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Fornavn</v-list-item-subtitle>
                    <v-list-item-title>{{
                      innstillinger.first_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Etternavn</v-list-item-subtitle>
                    <v-list-item-title>{{
                      innstillinger.last_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Epost</v-list-item-subtitle>
                    <v-list-item-title>{{
                      innstillinger.email
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Telefonnummer</v-list-item-subtitle>
                    <v-list-item-title>{{ phonenumber }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-subtitle>Konferanse</v-list-item-subtitle>
                    <v-list-item-title>{{ conference }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line v-if="conference_max_channels">
                  <v-list-item-content>
                    <v-list-item-subtitle
                      >Antall kanaler din konferanse</v-list-item-subtitle
                    >
                    <v-list-item-title>{{
                      conference_max_channels
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import { getUserInfo } from "../../utils/backend-api.js";
import innstillingerChangeForm from "@/components/InnstillingerChangeForm";
import { formatNumber } from "libphonenumber-js";

export default {
  data() {
    return {
      users: "",
      innstillinger: "",
      userobject: "",
    };
  },
  components: {
    innstillingerChangeForm,
    HeaderBox,
  },
  methods: {
    async getCurrentUserInfo() {
      try {
        const response = await getUserInfo(this.$store.state.userid);

        this.innstillinger = response;
        this.innstillinger.phonenumber = "+" + response.phonenumber;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    username() {
      return this.$store.state.username;
    },
    userid() {
      return this.$store.state.userid;
    },
    conference() {
      return this.$store.state.conference;
    },
    conference_max_channels() {
      return this.$store.state.conference_max_channels;
    },
    phonenumber() {
      return formatNumber("" + this.innstillinger.phonenumber, "International");
    },
  },
  watch: {
    // TODO: sjekk om watch er nødvendig
    // call again the method if the route changes
    $route: "getCurrentUserInfo",
  },
  mounted() {
    this.getCurrentUserInfo();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
