<template>
  <v-card>
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ conference }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ username }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="gotoPage(item.page)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Logg ut </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    logoutSoon: false,
    isIdle: true,
    helpKey: "",
    items: [
      { title: "Informasjon", icon: "info", page: "Info" },
      { title: "Innringere", icon: "person", page: "Innringere" },
      {
        title: "Selvregistrerte brukere",
        icon: "mdi-account-star",
        page: "Selvregistrerte",
      },
      {
        title: "Pågående samtaler",
        icon: "mdi-phone-incoming",
        page: "ActiveCalls",
      },
      { title: "Logg", icon: "mdi-phone-log", page: "Log" },
      { title: "Statistikk", icon: "mdi-chart-bar", page: "Statistikk" },
      {
        title: "Viderekoblinger",
        icon: "phone_forwarded",
        page: "Viderekoblinger",
      },
      { title: "SamLINK kalender", icon: "calendar_today", page: "Kalender" },
      { title: "SamLINK", icon: "mdi-speaker-wireless", page: "SamlinkStatus" },
      {
        title: "Retningslinjer",
        icon: "mdi-book-open",
        page: "Retningslinjer",
      },
      { title: "Innstillinger", icon: "settings", page: "Innstillinger" },
      // { title: "Websockets testing", icon: "settings", page: "Websockets" }
      // { title: "Logout", icon: "settings_power", page: "Info", }
    ],
  }),

  computed: {
    username() {
      return this.$store.state.username;
    },
    conference() {
      return this.$store.state.conference;
    },
    drawer: {
      get: function () {
        return this.$store.state.vueconfig.showMenu;
      },
      set: function () {},
    },
  },

  methods: {
    ...mapActions(["logout"]),
    gotoPage(page) {
      this.$router.push({ name: page });
    },
  },

  created() {},
};
</script>

<style></style>
