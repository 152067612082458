<template>
  <div>
    <v-spacer></v-spacer>
    <v-btn-toggle
      v-model="toggle_exclusive"
      color="primary"
      dense
      group
      mandatory
    >
      <v-btn
        :loading="loadingPhone"
        :disabled="loadingPhoneMonth || loadingInternet || loadingInternetMonth"
        v-on:click="getPhoneStats"
      >
        Telefon
        <v-icon right dark> mdi-phone </v-icon>
      </v-btn>
      <v-btn
        :loading="loadingPhoneMonth"
        :disabled="loadingPhone || loadingInternet || loadingInternetMonth"
        v-on:click="getPhoneMonthStats"
      >
        Telefon (måned)
        <v-icon right dark> mdi-phone </v-icon>
      </v-btn>
      <v-btn
        :loading="loadingInternet"
        :disabled="loadingPhone || loadingPhoneMonth || loadingInternetMonth"
        v-on:click="getInternetStats"
      >
        Internett
        <v-icon right dark> mdi-cellphone-wireless </v-icon>
      </v-btn>
      <v-btn
        :loading="loadingInternetMonth"
        :disabled="loadingPhone || loadingPhoneMonth || loadingInternet"
        v-on:click="getInternetMonthStats"
      >
        Internett (måned)
        <v-icon right dark> mdi-cellphone-wireless </v-icon>
      </v-btn>
    </v-btn-toggle>
    <div v-if="selectedDayStats">
      <v-data-table
        :headers="headersDayStats"
        :items="stats_entries"
        :items-per-page="40"
        class="elevation-1"
      >
        <template v-slot:no-data> Henter statistikk... </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.date }}</td>
            <td>{{ item.max_count }}</td>
            <td>{{ item.total_calls }}</td>
            <td>{{ item.unique_callers }}</td>
            <td>{{ item.conference_full }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-data-table
        :headers="headersMonthStats"
        :items="stats_entries"
        :items-per-page="25"
        class="elevation-1"
      >
        <template v-slot:no-data> Henter statistikk... </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.year }}</td>
            <td>{{ item.month }}</td>
            <td>{{ item.max_count }}</td>
            <td>{{ item.total_calls }}</td>
            <td>{{ item.daymax_unique_callers }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import {
  readPhoneStats,
  readInternetStats,
  readPhoneMonthStats,
  readInternetMonthStats,
} from '../../utils/backend-api.js';

export default {
  data() {
    return {
      headersDayStats: [
        { text: 'Dato', value: 'date' },
        { text: 'Max samtidige', value: 'max_count' },
        { text: 'Antall samtaler', value: 'total_calls' },
        { text: 'Unike nummer', value: 'unique_callers' },
        { text: 'Antall full konferanse', value: 'conference_full' },
      ],
      headersMonthStats: [
        { text: 'År', value: 'year' },
        { text: 'Måned', value: 'month' },
        { text: 'Max samtidige', value: 'max_count' },
        { text: 'Antall samtaler', value: 'total_calls' },
        { text: 'Max unike nummer per dag', value: 'daymax_unique_callers' },
      ],
      loadingPhone: false,
      loadingInternet: false,
      loadingPhoneMonth: false,
      loadingInternetMonth: false,
      selectedDayStats: true,
      selectedMonthStats: false,
      toggle_exclusive: undefined,
      stats_entries: [],
    };
  },
  methods: {
    async getPhoneStats() {
      this.loadingPhone = true;
      this.selectedDayStats = true;
      this.selectedMonthStats = false;
      try {
        const response = await readPhoneStats();
        this.stats_entries = response.data;
        this.loadingPhone = false;
      } catch (error) {
        // TODO: catch errors
      }
    },
    async getInternetStats() {
      this.loadingInternet = true;
      this.selectedDayStats = true;
      this.selectedMonthStats = false;
      try {
        const response = await readInternetStats();
        this.stats_entries = response.data;
        this.loadingInternet = false;
      } catch (error) {
        // TODO: catch errors
      }
    },
    async getPhoneMonthStats() {
      this.loadingPhoneMonth = true;
      this.selectedDayStats = false;
      this.selectedMonthStats = true;
      try {
        const response = await readPhoneMonthStats();
        this.stats_entries = response.data;
        this.loadingPhoneMonth = false;
      } catch (error) {
        // TODO: catch errors
      }
    },
    async getInternetMonthStats() {
      this.loadingInternetMonth = true;
      this.selectedDayStats = false;
      this.selectedMonthStats = true;
      try {
        const response = await readInternetMonthStats();
        this.stats_entries = response.data;
        this.loadingInternetMonth = false;
      } catch (error) {
        // TODO: catch errors
      }
    },
  },
  created() {
    this.getPhoneStats();
  },
};
</script>
