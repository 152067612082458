<template>
  <div>
    <div v-for="blog in blogs" :key="blog.id">
      <v-card>
        <v-card-title primary-title class="headline">{{
          blog.overskrift
        }}</v-card-title>
        <v-card-subtitle>
          Publisert: {{ blog.pub_dato | formatDateTime }}
        </v-card-subtitle>
        <v-card-text class="font-regular">
          <MarkdownViewer :text="blog.tekst" />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { readBlog } from "../../utils/backend-api";
import MarkdownViewer from "@/components/MarkdownViewer";
export default {
  data() {
    return {
      blogs: [],
    };
  },
  components: {
    MarkdownViewer,
  },
  filters: {
    formatDateTime: function (datetime) {
      const thisDateTime = new Date(datetime);

      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        // hour: "numeric",
        // minute: "numeric",
        // second: "numeric",
        hour12: false,
      };
      return new Intl.DateTimeFormat("nb-NO", options).format(thisDateTime);
    },
  },
  methods: {
    async getBlog() {
      try {
        const response = await readBlog();
        this.blogs = response.data;
      } catch (error) {
        // TODO: catch errors
      }
    },
  },

  created() {
    this.getBlog();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
