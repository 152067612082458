<template>
  <div>
    <v-btn color="blue lighten-2" dark v-on:click="websocketListen()">
      Websocket listen on
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    websocketListen() {
      // this.$options.sockets.onmessage = (data) => console.log('HER ER DATA: ', data)
      this.$options.sockets.onmessage = (data) =>
        console.log("data: ", data["data"]);
      console.log("Listening...");
    },
  },
};
</script>
