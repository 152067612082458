<template>
  <div class="innringerComponent">
    <v-fab-transition>
      <v-btn
        color="primary"
        @click.stop="dialog = true"
        v-model="fab"
        dark
        fab
        fixed
        bottom
        right
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialog">
      <v-card class="mx-auto" max-width="600">
        <v-card-title>
          <span class="headline">Ny/endre innringer</span>
        </v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <v-text-field
                label="Innringer Navn"
                v-model="editedItem.navn"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3" xl="3">
              <v-text-field
                label="Telefonnummer"
                v-model="editedItem.telefon"
                :rules="phonenumberRules"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-checkbox
                label="Internett"
                v-model="editedItem.internettbruker"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-select
                :items="tilgangAlternatives"
                v-model="editedItem.tilgang"
                single-line
                item-text="text"
                item-value="text"
                label="Tilgang"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="Startdato"
                v-model="editedItem.startdato"
              ></v-text-field>
              <v-date-picker
                v-model="editedItem.startdato"
                color="green lighten-1"
                first-day-of-week="1"
                locale="no-nb"
                full-width
                :landscape="$vuetify.breakpoint.smAndUp"
              ></v-date-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="Sluttdato"
                v-model="editedItem.sluttdato"
              ></v-text-field>
              <v-date-picker
                v-model="editedItem.sluttdato"
                color="red lighten-1"
                first-day-of-week="1"
                locale="no-nb"
                full-width
                :landscape="$vuetify.breakpoint.smAndUp"
              ></v-date-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                label="Kommentar"
                v-model="editedItem.kommentar"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.native="close">Avbryt</v-btn>
          <v-btn color="blue darken-1" text @click.native="save">Lagre</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <HeaderBox header="Innringere" helpkey="innringere" />
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

    <v-text-field v-model="search" append-icon="search" label="Søk" single-line>
    </v-text-field>
    <v-spacer></v-spacer>
    <v-btn-toggle
      v-model="menu_toggle"
      color="amber darken-4"
      dense
      group
      mandatory
    >
      <v-btn> Aktive </v-btn>
      <v-btn> Inaktive </v-btn>
    </v-btn-toggle>

    <v-data-table
      :headers="headers"
      :items="selectedCallers"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:[`item.internettbruker`]="{ item }"
        ><v-icon class="mr-2" color="teal" v-if="item.internettbruker"
          >done</v-icon
        >
      </template>
      <template v-slot:[`item.telefon`]="{ item }">{{
        formatPhonenumber(item.telefon)
      }}</template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon class="mr-2" color="teal" @click="editItem(item)">
          edit
        </v-icon>
        <v-icon color="pink" @click="deleteItem(item)"> delete </v-icon>
      </template>
      <template v-slot:no-data> Ingen innringere </template>
    </v-data-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import ConfirmDialog from "@/components/ConfirmDialog";
import { formatNumber } from "libphonenumber-js";
import {
  createInnringer,
  readInnringere,
  updateInnringer,
  deleteInnringer,
} from "../../utils/backend-api";

export default {
  data() {
    return {
      dialog: false,
      fab: true,
      editing: false,
      search: "",
      menu_toggle: undefined,
      headers: [
        { text: "Telefon", value: "telefon" },
        { text: "Navn", value: "navn" },
        { text: "Tilgang", value: "tilgang" },
        { text: "Startdato", value: "startdato" },
        { text: "Sluttdato", value: "sluttdato" },
        { text: "Internett", value: "internettbruker" },
        { text: "Kommentar", value: "kommentar" },
        { text: "Endre/Slett", value: "action", sortable: false },
      ],
      innringere: [],
      editedItem: {
        name: "",
      },
      defaultItem: {
        name: "",
      },
      tilgang: { text: "Lytter" },
      tilgangAlternatives: [
        { text: "Lytter" },
        { text: "Samlingshus" },
        { text: "Telefonansvarlig" },
      ],

      phonenumberRules: [
        (v) => !!v || "Telefonnummer på fylles inn",
        (v) =>
          /^[0-9]{4,50}$/.test(v) ||
          "Begynn med landskode og så telefonnummer. Kun siffer.",
        (v) =>
          (v && v.length > 4) || "Telefonnummeret må inneholde flere siffer.",
        (v) => (v && v.length < 29) || "Telefonnummeret er for langt.",
      ],
    };
  },

  components: {
    ConfirmDialog,
    HeaderBox,
  },

  computed: {
    // formTitle () {
    //   return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    // }
    selectedCallers: function () {
      if (this.menu_toggle == 0) {
        // Active callers
        return this.innringere.filter(function (caller) {
          var today = new Date().toISOString().slice(0, 10);
          var aktiv = caller.startdato <= today && caller.sluttdato >= today;
          return aktiv && !caller.selvregistrert;
        });
      } else {
        // Inactive callers
        return this.innringere.filter(function (caller) {
          var today = new Date().toISOString().slice(0, 10);
          var aktiv = caller.startdato <= today && caller.sluttdato >= today;
          return !aktiv && !caller.selvregistrert;
        });
      }
    },
  },

  methods: {
    async getInnringere() {
      try {
        const response = await readInnringere();
        this.innringere = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editing = true;
      this.dialog = true;
    },

    async deleteItem(item) {
      if (
        await this.$refs.confirmDialog.open(
          "Slette innringer",
          "Er du sikker på at du vil slette?",
          { color: "red" }
        )
      ) {
        // yes
        try {
          await deleteInnringer(item.id);
          this.getInnringere();
        } catch (error) {
          console.log(error);
        }
      } else {
        // cancel
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.editing) {
        try {
          await updateInnringer({
            id: this.editedItem.id,
            navn: this.editedItem.navn,
            telefon: this.editedItem.telefon,
            internettbruker: this.editedItem.internettbruker,
            tilgang: this.editedItem.tilgang,
            startdato: this.editedItem.startdato,
            sluttdato: this.editedItem.sluttdato,
            kommentar: this.editedItem.kommentar,
          });
          this.getInnringere();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await createInnringer({
            navn: this.editedItem.navn,
            telefon: this.editedItem.telefon,
            internettbruker: this.editedItem.internettbruker,
            tilgang: this.editItem.tilgang,
            startdato: this.editedItem.startdato,
            sluttdato: this.editedItem.sluttdato,
            kommentar: this.editedItem.kommentar,
          });
          this.getInnringere();
        } catch (error) {
          console.log(error);
        }
      }
      this.close();
    },

    formatPhonenumber: function (value) {
      value = "+" + value;
      value = formatNumber(value, "International");
      return value;
    },
  },

  watch: {
    // call again the method if the route changes
    $route: "getInnringere",
  },

  created() {
    this.getInnringere();
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
