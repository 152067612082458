<template>
  <v-app id="inspire">
    <Login>
      <v-app-bar
        color="amber"
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        dark
        app
        fixed
      >
        <v-app-bar-nav-icon @click="drawerSwitch"></v-app-bar-nav-icon>
        <span class="title ml-3 mr-5">Samlingstelefonen</span>
      </v-app-bar>

      <NavigationMenu />

      <v-main>
        <div class="test-container" v-if="hashChanged">
          <v-card color="red lighten-3">
            <p class="text-center font12">
              En oppdatering er tilgjengelig. Vennligst lagre det du holder på
              med og trykk deretter på oppdater nedenfor. Du kan også akseptere
              oppdateringen ved å trykke på oppdater-knappen øverst i
              nettleseren.
            </p>
            <p class="text-center">
              <span class="font10"
                >Hvis du ikke oppdaterer kan du oppleve feil.</span
              >
            </p>
            <div class="refresh-button text-center">
              <v-btn color="red" @click="reloadApp"> Oppdater </v-btn>
            </div>
          </v-card>
        </div>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <v-footer class="justify-center pl-0 grey--text" inset app>
        <span>&copy; Den læstadianske forsamling 2020.</span>
      </v-footer>
    </Login>
  </v-app>
</template>

<script>
import Login from "./views/Login";
import { mapActions } from "vuex";
import NavigationMenu from "@/components/NavigationMenu";
import { testMixin } from "@/components/mixins/test.mixin.js";

export default {
  components: {
    NavigationMenu,
    Login,
  },
  mixins: [testMixin],
  data: () => ({
    logoutSoon: false,
    isIdle: true,
    helpKey: "",
  }),

  computed: {
    username() {
      return this.$store.state.username;
    },
    conference() {
      return this.$store.state.conference;
    },
    drawer: {
      // getter
      get: function () {
        return this.$store.state.vueconfig.showMenu;
      },
      // setter
      set: function () {},
    },
  },

  onIdle() {
    this.isIdle = true;
  },

  onActive() {
    this.isIdle = false;
  },

  props: {
    source: String,
  },

  methods: {
    ...mapActions(["logout", "syncSession"]),
    drawerSwitch() {
      this.$store.commit("VUE_MENU_ONOFF_SWITCH");
    },
  },

  mounted() {
    this.syncSession();
    this.versionCheck();
  },

  created() {},
};
</script>

<style></style>
