<!--suppress ALL -->
<template>
  <div>
    <HeaderBox header="SamLINK status" helpkey="samlink-status"/>
    <SamLink
      v-bind:key="samlink.key"
      v-for="samlink in samlinks.sort(
        (a, b) => (a.name.toUpperCase() > b.name.toUpperCase() && 1) || -1
      )"
      :device="samlink.key"
      :samlink="samlink"
    />

    <SamlinkConfigureWlan/>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import SamLink from "../components/SamLink";
import {mapActions} from "vuex";
import store from "../store";
import {orderBy} from "lodash";
import SamlinkConfigureWlan from "../components/SamlinkConfigureWlan";

export default {
  components: {SamlinkConfigureWlan, HeaderBox, SamLink},
  mounted() {
    this.getSamlinks();
  },
  computed: {
    samlinks() {
      return orderBy(store.state.samlinks, "name");
    },
  },
  methods: {
    ...mapActions(["getSamlinks"]),

  },

  data() {
    return {
      ssids: [],
      device: "",
      password: "",
      ssid: "",
      fab: true,
      ip: "",
      show: false,
      record: 64,
      play: 50,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
