<template>
  <v-card>
    <v-card-text>
      For at brukere via internett skal kunne lytte til din konferanse, må
      brukerne få følgende beskjed: "Åpne nettsiden samlingstelefonen.net, velg
      forsamling
      <b>{{ conference }}</b
      >, bruk passord <b>{{ conference_password }}</b>
      og følg instruksjonene på skjermen. Passordet bør holdes internt i egen
      forsamling. Det er ikke mulig å registrere seg i mer enn en forsamling."
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    conference() {
      return this.$store.state.conference;
    },
    conference_password() {
      return this.$store.state.conference_password;
    },
  },
};
</script>
