<template>
  <div>
    <div v-if="text === 'send'">
      <v-btn small color="green">Send</v-btn>
    </div>
    <div v-else-if="text === 'receive'">
      <v-btn small color="red">Motta</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
  },
};
</script>
