<template>
  <div id="editor">
    <div v-html="compiledMarkdown"></div>
  </div>
</template>
<script>
import marked from "marked";
export default {
  props: {
    text: String,
  },
  data() {
    return {};
  },
  computed: {
    compiledMarkdown: function () {
      return marked(this.text);
    },
  },
  methods: {},
};
</script>
