<template>
  <div>
    <v-toolbar color="white">
      <v-switch
        v-model="switch1"
        :label="`Visningsmodus: tabell/kort`"
      ></v-switch>
      <v-spacer></v-spacer>

      <v-dialog v-model="newDialog" max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="mb-2" color="primary" dark rounded
            >Legg til ny
          </v-btn>
        </template>

        <v-card>
          <v-col>
            <v-card color="blue darken-1" class="white--text">
              <v-card-title primary-title>
                <div>
                  <div class="headline">Ny aktivitet</div>
                </div>
              </v-card-title>
            </v-card>
          </v-col>

          <v-card-text>
            <v-container fluid>
              <span>
                1) Velg SamLINK (noen forsamlinger har flere). 2) Velg send
                eller motta. (Dersom du vil motta lyd fra annen forsamling må du
                også bestille viderekobling for denne datoen.) 3) Velg
                klokkeslett når samlingen skal begynne. Sendingen starter
                automatisk 15 minutter før angitt klokkeslett og stopper
                automatisk 4 timer senere. 4) Velg en eller flere datoer. 5)
                Trykk 'Lagre'.
              </span>
            </v-container>

            <v-container fluid>
              <v-row>
                <v-col>
                  <v-select
                    :items="samlinkAlternatives"
                    v-model="Item.name"
                    label="SamLINK-navn"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-radio-group v-model="Item.transceive" row>
                    <v-radio label="Send" value="send" color="red"></v-radio>
                    <v-radio
                      label="Motta"
                      value="receive"
                      color="green"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-time-picker
                    v-model="Item.starttime"
                    format="24hr"
                    :landscape="$vuetify.breakpoint.mdAndUp"
                    :allowed-minutes="allowedStep"
                  >
                  </v-time-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    solo
                    v-model="Item.info"
                    name="input-7-4"
                    label="Plass for egne notater."
                    value=""
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-date-picker
                    v-model="dates"
                    multiple
                    first-day-of-week="1"
                    locale="nb"
                    full-width
                    :landscape="$vuetify.breakpoint.mdAndUp"
                  ></v-date-picker>
                </v-col>

                <v-col>
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template>
                      <v-combobox
                        slot="activator"
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="event"
                        readonly
                      ></v-combobox>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Avbryt</v-btn>
            <v-btn color="blue darken-1" text @click="save">Lagre</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editDialog" max-width="1000px">
        <v-card>
          <v-col>
            <v-card color="blue darken-1" class="white--text">
              <v-card-title primary-title>
                <div>
                  <div class="headline">Redigere aktivitet</div>
                  <span>
                    Her kan du legge til og slette datoer for denne aktiviteten.
                    Du kan også endre egne notater.
                  </span>
                </div>
              </v-card-title>
            </v-card>
          </v-col>

          <v-card-title>
            <span class="headline">
              {{ Item.name }} kl {{ stripSeconds(Item.starttime) }}
            </span>
            <sendReceiveButton v-bind:text="Item.transceive" />
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-row row wrap>
                <v-col>
                  <v-textarea
                    solo
                    v-model="Item.info"
                    name="input-7-4"
                    label="Plass for egne notater."
                    value=""
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-date-picker
                    v-model="dates"
                    multiple
                    first-day-of-week="1"
                    locale="nb"
                    full-width
                    :landscape="$vuetify.breakpoint.smAndUp"
                  ></v-date-picker>
                </v-col>

                <v-col>
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    v-model="menu"
                    :nudge-right="40"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template>
                      <v-combobox
                        slot="activator"
                        v-model="dates"
                        multiple
                        chips
                        small-chips
                        prepend-icon="event"
                        readonly
                      ></v-combobox>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Avbryt</v-btn>
            <v-btn color="blue darken-1" text @click="update">Lagre</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-row row justify-center v-if="switch1">
      <v-col>
        <v-card color="grey darken-1" class="white--text">
          <v-container fluid grid-list-md>
            <v-row row wrap>
              <v-col v-for="(event, index) in events" :key="index">
                <v-card>
                  <v-card-title primary-title>
                    <div>
                      <h3 class="headline">
                        {{ event.name }} kl {{ stripSeconds(event.starttime) }}
                      </h3>
                    </div>
                    <sendReceiveButton v-bind:text="event.transceive" />
                  </v-card-title>
                  <v-card-text>
                    <span>{{ event.info }}</span>
                    <v-combobox
                      slot="activator"
                      v-model="event.dates"
                      multiple
                      chips
                      prepend-icon="event"
                      readonly
                    ></v-combobox>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="editItem(event)">Endre</v-btn>
                    <v-btn @click="deleteItem(event)">Slett</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="events"
      class="elevation-1"
      v-if="!switch1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Tabell</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.dates`]="{ item }">
        <v-combobox
          slot="activator"
          v-model="item.dates"
          multiple
          chips
          small-chips
          prepend-icon="event"
          readonly
        ></v-combobox>
      </template>
      <template v-slot:[`item.transceive`]="{ item }">
        <sendReceiveButton v-bind:text="item.transceive" />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="green" class="mr-2" @click="editItem(item)">
          edit
        </v-icon>
        <v-icon color="red" @click="deleteItem(item)"> delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// <sendReceiveButton v-bind:text="props.item.transceive" />
import {
  readEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  readSamlinks,
} from "../../utils/backend-calendar-api.js";
import store from "../store";
import sendReceiveButton from "./SendReceiveButton";
export default {
  components: {
    sendReceiveButton,
  },
  data: function () {
    return {
      switch1: false,
      newDialog: false,
      editDialog: false,
      menu: false,
      headers: [
        { text: "SamLINK-navn", value: "name" },
        { text: "Tidspunkt", value: "starttime" },
        { text: "Send/Motta", value: "transceive" },
        { text: "Info", value: "info" },
        { text: "Datoer", value: "dates" },
        { text: "Endre/Slett", value: "action", sortable: false },
      ],
      events: [],
      editedIndex: -1,
      Item: {
        name: "",
        starttime: "11:00:00",
        transceive: "send",
      },
      dates: [],
      samlinkAlternatives: [],
      selectedSamlink: "",
    };
  },

  methods: {
    allowedStep: (m) => m % 15 === 0,

    stripSeconds(time) {
      return time.substring(0, 5);
    },

    clearItem() {
      this.Item = {
        name: "",
        starttime: "11:00:00",
      };
      this.dates = [];
    },

    findSamlink(item) {
      return item.text === this.Item.name;
    },

    async getSamlinks() {
      try {
        const response = await readSamlinks();
        // Convert obj.name to obj.text (neccessary for vue)
        this.samlinkAlternatives = response
          .filter((samlink) => samlink.owner === store.state.conference)
          .map(function (obj) {
            return {
              key: obj.key,
              text: obj.name,
            };
          });
      } catch (error) {
        // TODO: catch error
        console.log("error: ", error);
      }
    },

    async getEvents() {
      try {
        const response = await readEvents();
        this.events = response;
      } catch (error) {
        // TODO: catch error
        console.log("error: ", error);
      }
    },

    close() {
      this.clearItem();
      this.newDialog = false;
      this.editDialog = false;
    },

    async save() {
      try {
        await createEvent({
          name: this.Item.name,
          starttime: this.Item.starttime,
          info: this.Item.info,
          transceive: this.Item.transceive,
          samlink: this.samlinkAlternatives.find(this.findSamlink).key,
          dates: this.dates,
        });
      } catch (error) {
        // TODO: catch error
        console.log("error: ", error);
      }
      this.getEvents();
      this.close();
    },

    async update() {
      try {
        await updateEvent({
          id: this.Item.id,
          name: this.Item.name,
          starttime: this.Item.starttime,
          info: this.Item.info,
          transceive: this.Item.transceive,
          samlink: this.samlinkAlternatives.find(this.findSamlink).key,
          dates: this.dates,
        });
      } catch (error) {
        console.log(error);
      }
      this.getEvents();
      this.close();
    },

    async deleteItem(item) {
      if (confirm("Er du sikker på at du vil slette?")) {
        try {
          await deleteEvent(item);
          this.getEvents();
        } catch (error) {
          console.log(error);
        }
      }
    },

    editItem(item) {
      this.Item = Object.assign({}, item);
      this.dates = item.dates;
      this.editDialog = true;
    },
  },

  created() {
    this.getEvents();
    this.getSamlinks();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ny hendelse" : "Rediger dato";
    },
  },
  watch: {
    // Vet ikke hensikten med dette (se Dok under vuetifyjs.com --> data-tables)
    dialog(val) {
      val || this.close();
    },
  },
};
</script>
