import axios from "axios";
import { apiDummyLogRefresh } from "../../../utils/backend-api";

const FILE_URL = "/version.json";
// const FRONTEND_URL = `${window.location.host}/`;

export const testMixin = {
  data() {
    return {
      currentHash: "{{POST_BUILD_ENTERS_HASH_HERE}}",
      newHash: "",
      hashChanged: false,
    };
  },
  methods: {
    versionCheck(frequency = 1000 * 60 * 2) {
      setInterval(() => {
        this.checkFrontendVersion();
      }, frequency);
    },

    async checkFrontendVersion() {
      try {
        const fileResponse = await axios.get(
          `${FILE_URL}?t=${new Date().getTime()}`
        );

        this.newHash = fileResponse.data.hash;
        this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
      } catch (error) {
        // TODO
      }
    },

    hasHashChanged(currentHash, newHash) {
      console.log("1.9");
      if (!currentHash) {
        return true;
      }

      /*
      if (newHash !== "{{POST_BUILD_ENTERS_HASH_HERE}}") {
        console.log("TRUE");
        return true;
      }
      */

      return currentHash !== newHash;
    },

    reloadApp() {
      // console.log('Message from testMixin.')
      this.currentHash = this.newHash;
      apiDummyLogRefresh(this.$store.state.username);
      window.location.reload();
    },
  },
};
