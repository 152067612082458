<template>
  <div class="log">
    <HeaderBox header="Logg" helpkey="logpage" />
    <logTable></logTable>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import logTable from "../components/LogTable.vue";

export default {
  data() {
    return {};
  },

  components: {
    HeaderBox,
    logTable,
  },
};
</script>
