<template>

  <v-list-item link>
    <v-list-item-title @click.stop="dialog = true">Endre navn</v-list-item-title>


    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">Endre samlink navn</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="samlinkLocal.name"
              :rules="nameRules"
              :counter="30"
              label="Navn"
              required
            ></v-text-field>
            <v-btn :disabled="!valid" @click="submit"> lagre</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-list-item>

</template>

<script>
import {updateSamlinkName} from "../../utils/backend-api";

export default {
  name: "SamlinkChangeName",
  props: {samlink: {type: Object, required: true}},
  data: () => ({
    fab: true,
    dialog: false,
    valid: true,
    nameRules: [
      (v) => !!v || "Navn på fylles inn",
      (v) => (v && v.length <= 30) || "Navn må være kortere enn 30 tegn",
    ],
  }),

  computed: {
    samlinkLocal() {
      return {...this.samlink}
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        console.log("SAMLINK UPDATE: ", this.samlinkLocal);
        try {
          await updateSamlinkName(this.samlinkLocal);
          // Emitted signal can be catched by parent:
          this.$emit("samlink-updated");
          this.dialog = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
