<template>
  <div class="innringerComponent">
    <v-dialog v-model="dialog">
      <v-card class="mx-auto" max-width="600">
        <v-card-title>
          <span class="headline"
            >{{ editedItem.navn }} {{ editedItem.telefon }}</span
          >
        </v-card-title>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-checkbox
                label="Blokkert (kryss av dersom du vil blokkere lytter)"
                v-model="editedItem.blokkert"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                label="Kommentar"
                v-model="editedItem.kommentar"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.native="close">Avbryt</v-btn>
          <v-btn color="blue darken-1" text @click.native="save">Lagre</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <HeaderBox header="Selvregistrerte brukere" helpkey="selvregistrerte" />
    <ConferencePasswordBox />
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

    <v-text-field v-model="search" append-icon="search" label="Søk" single-line>
    </v-text-field>
    <v-spacer></v-spacer>
    <v-btn-toggle
      v-model="menu_toggle"
      color="amber darken-4"
      dense
      group
      mandatory
    >
      <v-btn> Aktive </v-btn>
      <v-btn> Blokkerte </v-btn>
    </v-btn-toggle>

    <v-data-table
      :headers="headers"
      :items="selectedCallers"
      :search="search"
      class="elevation-1 lighten-4"
    >
      <template v-slot:[`item.telefon`]="{ item }">
        {{ formatPhonenumber(item.telefon) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-icon class="mr-2" color="teal" @click="editItem(item)">
          edit
        </v-icon>
      </template>
      <template v-slot:[`no-data`]> Ingen innringere </template>
    </v-data-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import ConferencePasswordBox from "@/components/ConferencePasswordBox";
import ConfirmDialog from "@/components/ConfirmDialog";
import { formatNumber } from "libphonenumber-js";
import {
  readInnringere,
  updateInnringer,
  deleteInnringer,
} from "../../utils/backend-api";

export default {
  data() {
    return {
      dialog: false,
      editing: false,
      search: "",
      menu_toggle: undefined,
      headers: [
        { text: "Telefon", value: "telefon" },
        { text: "Navn", value: "navn" },
        { text: "Startdato", value: "startdato" },
        { text: "Katalog", value: "yellowpage" },
        { text: "Kommentar", value: "kommentar" },
        { text: "Endre", value: "action", sortable: false },
      ],
      innringere: [],
      editedItem: {
        name: "",
      },
      defaultItem: {
        name: "",
      },
      tilgang: { text: "Lytter" },
      tilgangAlternatives: [
        { text: "Lytter" },
        { text: "Samlingshus" },
        { text: "Telefonansvarlig" },
      ],

      phonenumberRules: [
        (v) => !!v || "Telefonnummer på fylles inn",
        (v) =>
          /^[0-9]{4,50}$/.test(v) ||
          "Begynn med landskode og så telefonnummer. Kun siffer.",
        (v) =>
          (v && v.length > 4) || "Telefonnummeret må inneholde flere siffer.",
        (v) => (v && v.length < 29) || "Telefonnummeret er for langt.",
      ],
    };
  },

  components: {
    ConfirmDialog,
    HeaderBox,
    ConferencePasswordBox,
  },

  computed: {
    selectedCallers: function () {
      if (this.menu_toggle == 0) {
        // Active callers
        return this.innringere.filter(function (caller) {
          return caller.selvregistrert && !caller.blokkert;
        });
      } else {
        // Blocked callers
        return this.innringere.filter(function (caller) {
          return caller.selvregistrert && caller.blokkert;
        });
      }
    },
  },

  methods: {
    async getInnringere() {
      try {
        const response = await readInnringere();
        this.innringere = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editing = true;
      this.dialog = true;
    },

    async deleteItem(item) {
      if (
        await this.$refs.confirmDialog.open(
          "Delete",
          "Er du sikker på at du vil slette?",
          { color: "red" }
        )
      ) {
        // yes
        try {
          await deleteInnringer(item.id);
          this.getInnringere();
        } catch (error) {
          console.log(error);
        }
      } else {
        // cancel
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async save() {
      if (this.editing) {
        try {
          await updateInnringer({
            id: this.editedItem.id,
            navn: this.editedItem.navn,
            telefon: this.editedItem.telefon,
            tilgang: this.editedItem.tilgang,
            startdato: this.editedItem.startdato,
            sluttdato: this.editedItem.sluttdato,
            blokkert: this.editedItem.blokkert,
            kommentar: this.editedItem.kommentar,
          });
          this.getInnringere();
        } catch (error) {
          console.log(error);
        }
      }
      this.close();
    },

    formatPhonenumber: function (value) {
      value = "+" + value;
      value = formatNumber(value, "International");
      return value;
    },
  },

  watch: {
    // call again the method if the route changes
    $route: "getInnringere",
  },

  created() {
    this.getInnringere();
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
