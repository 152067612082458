// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import "./plugins/vuetify";
import App from "./App";
import router from "./router";
import VueCookies from "vue-cookies";
import store from "./store";
import VueNativeSock from "vue-native-websocket";
import vuetify from "./plugins/vuetify";

// import Vuetify from 'vuetify'
// import IdleVue from 'idle-vue'
// import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
// Vue.use(IdleVue, {
//   eventEmitter: eventsHub,
//   idleTime: 10000
// })
//
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(VueCookies);

Vue.use(VueNativeSock, "ws://localhost:8000/ws", {
  store: store,
  format: "json",
  reconnection: true,
  connectManually: true,
  passToStoreHandler: function (eventName, event) {
    if (!eventName.startsWith("SOCKET_")) {
      return;
    }

    let msg = event;
    if (this.format === "json" && event.data) {
      msg = JSON.parse(event.data);
      switch (msg.type) {
        case "samlink_current_state":
          console.log(msg);
          this.store.commit("SET_SAMLINK_STATE", {
            samlinkId: msg.samlink,
            status: msg.state,
            time: msg.time,
          });
          break;

        case "samlink_wanted_state_changed":
          this.store.commit("SET_SAMLINK_WANTED_STATE", {
            samlinkId: msg.samlink,
            status: msg.state,
          });
          break;
      }
    }
  },
});

Vue.config.productionTip = false;

window.vm = new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   template: '<App/>',
//   components: { App }
// })
