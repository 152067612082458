<template>
  <v-card class="pa-2" tile>
    <v-card-title> Systemadministatorer </v-card-title>
    <v-card-text>
      <b>Christoffer Blindheim</b><br />
      christoffer.blindheim@gmail.com <br />
      Telefon: +47 913 13 880<br />
      <br />

      <b>Stein Roald Bolle</b> <br />
      srbolle@gmail.com <br />
      Telefon: +47 924 68 438 <br />
      <br />

      <b>Samuel Nilsson</b> <br />
      mail@samuelnilsson.me <br />
      Telefon: +46 70 257 70 50
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
