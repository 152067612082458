<template>
  <div>
    <v-btn color="blue lighten-2" dark v-on:click="pingServer()">
      Ping websocket
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    pingServer() {
      this.$socket.sendObj({
        command: "text_message",
        message: "Ping from vue",
      });
      console.log("Message sent.");
    },
  },
};
</script>
