<template>
  <div>
    <HeaderBox header="Innringere" helpkey="innringere" />
    <callers-table />
  </div>
</template>
<script>
import HeaderBox from "@/components/HeaderBox";
import CallersTable from "@/components/CallersTable";
export default {
  components: {
    CallersTable,
    HeaderBox,
  },
};
</script>
