<template>
  <div>
    <v-fab-transition>
      <v-btn
        color="primary"
        @click.stop="dialog = true"
        v-model="fab"
        dark
        fab
        fixed
        bottom
        right
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialog">
      <v-card class="mx-auto" max-width="600">
        <v-card-title>
          <span class="headline">Ny viderekobling</span>
        </v-card-title>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-select
                :items="konferanser"
                v-model="newItem.konferanse"
                single-line
                item-text="konferansenavn"
                item-value="konferansenavn"
                label="Konferanse"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="Startdato"
                v-model="newItem.startdato"
              ></v-text-field>
              <v-date-picker
                v-model="newItem.startdato"
                color="green lighten-1"
                first-day-of-week="1"
                locale="no-nb"
                full-width
                :landscape="$vuetify.breakpoint.smAndUp"
              ></v-date-picker>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                label="Sluttdato"
                v-model="newItem.sluttdato"
              ></v-text-field>
              <v-date-picker
                v-model="newItem.sluttdato"
                color="red lighten-1"
                first-day-of-week="1"
                locale="no-nb"
                full-width
                :landscape="$vuetify.breakpoint.smAndUp"
              ></v-date-picker>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click.native="close">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click.native="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section class="pb-5">
      <HeaderBox
        header="Mine bestilte viderekoblinger"
        helpkey="bestilte-overføringer"
      />

      <v-data-table
        :headers="tableheaderSentRequests"
        :items="viderekoblinger"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`no-data`]>
          Du har ingen henvendelser om viderekobling
        </template>
        <template v-slot:[`item.godkjent`]="{ item }">
          <v-icon class="mr-2" color="teal" v-if="item.godkjent"> done </v-icon>
          <v-icon color="red" v-else> highlight_off </v-icon>
        </template>
        <template v-slot:[`item.aktiv`]="{ item }">
          <v-icon
            class="mr-2"
            color="teal"
            v-if="item.aktiv"
            @click="changeAktiv(item)"
          >
            done
          </v-icon>
          <v-icon color="red" v-else @click="changeAktiv(item)">
            highlight_off
          </v-icon>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <div v-if="isDateAfterToday(item.startdato)">
            <v-icon color="pink" @click="deleteItem(item)"> delete </v-icon>
          </div>
        </template>
      </v-data-table>
    </section>

    <section>
      <HeaderBox
        header="Mottatte forespørsler om viderekoblinger"
        helpkey="mottatte-overføringer"
      />

      <v-data-table
        :headers="tableheaderReceivedRequests"
        :items="viderekoblingerFraAndre"
        :disable-pagination="true"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:[`no-data`]>
          Ingen mottatte henvendelser om viderekobling
        </template>
        <template v-slot:[`item.godkjent`]="{ item }">
          <v-icon
            class="mr-2"
            color="teal"
            v-if="item.godkjent"
            @click="changeGodkjent(item)"
          >
            done
          </v-icon>
          <v-icon color="red" v-else @click="changeGodkjent(item)">
            highlight_off
          </v-icon>
        </template>
        <template v-slot:[`item.aktiv`]="{ item }">
          <v-icon class="mr-2" color="teal" v-if="item.aktiv"> done </v-icon>
          <v-icon color="red" v-else> highlight_off </v-icon>
        </template>
      </v-data-table>
    </section>
  </div>
</template>
<script>
import HeaderBox from '@/components/HeaderBox';
import {
  readKonferanser,
  readViderekoblinger,
  createViderekobling,
  updateViderekobling,
  readViderekoblingerFraAndre,
  updateViderekoblingFraAndre,
  deleteViderekobling,
} from '../../utils/backend-api';

export default {
  data() {
    return {
      today: '',
      dialog: false,
      fab: true,
      editing: false,
      tableheaderSentRequests: [
        { text: 'Konferanse', value: 'konferanse' },
        { text: 'Fra dato', value: 'startdato' },
        { text: 'Til dato', value: 'sluttdato' },
        { text: 'Godkjent', value: 'godkjent' },
        { text: 'Aktiv', value: 'aktiv' },
        { text: 'Slett', value: 'action', sortable: false },
      ],
      tableheaderReceivedRequests: [
        { text: 'Konferanse', value: 'bestiller' },
        { text: 'Fra dato', value: 'startdato' },
        { text: 'Til dato', value: 'sluttdato' },
        { text: 'Godkjent', value: 'godkjent' },
        { text: 'Aktiv', value: 'aktiv' },
      ],
      konferanser: [],
      viderekoblinger: [],
      viderekoblingerFraAndre: [],
      newItem: {
        konferanse: '',
      },
      defaultItem: {
        konferanse: '',
      },
    };
  },
  components: {
    HeaderBox,
  },
  methods: {
    async getKonferanser() {
      try {
        this.konferanser = await readKonferanser();
      } catch (error) {
        console.log(error);
      }
    },

    async getViderekoblinger() {
      try {
        this.viderekoblinger = await readViderekoblinger();
      } catch (error) {
        console.log(error);
      }
    },

    async getViderekoblingerFraAndre() {
      try {
        this.viderekoblingerFraAndre = await readViderekoblingerFraAndre();
      } catch (error) {
        console.log(error);
      }
    },

    async save() {
      if (this.editing) {
        try {
          await updateViderekobling({
            id: this.newItem.id,
            konferanse: this.newItem.konferanse,
            startdato: this.newItem.startdato,
            sluttdato: this.newItem.sluttdato,
          });
          this.getViderekoblinger();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await createViderekobling({
            konferanse: this.newItem.konferanse,
            startdato: this.newItem.startdato,
            sluttdato: this.newItem.sluttdato,
          });
          this.getViderekoblinger();
        } catch (error) {
          console.log(error);
        }
      }
      this.close();
    },

    close() {
      this.dialog = false;
      this.newItem = Object.assign({}, this.defaultItem)
    },

    editItem(item) {
      // this.editedIndex = this.innringere.indexOf(item)
      this.newItem = Object.assign({}, item);
      this.editing = true;
      this.dialog = true;
    },

    async deleteItem(item) {
      confirm('Er du sikker på at du vil slette?');
      try {
        await deleteViderekobling(item.id);
        this.getViderekoblinger();
      } catch (error) {
        console.log(error);
      }
    },

    async changeAktiv(item) {
      try {
        await updateViderekobling({
          id: item.id,
          aktiv: !item.aktiv,
        });
        this.getViderekoblinger();
      } catch (error) {
        console.log(error);
      }
    },

    async changeGodkjent(item) {
      try {
        await updateViderekoblingFraAndre({
          id: item.id,
          godkjent: !item.godkjent,
        });
        this.getViderekoblingerFraAndre();
      } catch (error) {
        console.log(error);
      }
    },

    convertDate(date_) {
      return new Date(date_);
    },

    isDateAfterToday(date_) {
      var d1 = new Date(date_);
      var today = new Date();
      return d1 > today;
    },
  },

  watch: {
    // '$route': 'getViderekoblinger'  TODO: check if needed
  },

  created() {
    this.getKonferanser();
    this.getViderekoblinger();
    this.getViderekoblingerFraAndre();
  },
  mounted() {
    this.today = this.convertDate(new Date().toISOString().split('T')[0]);
  },
  updated() {},
};
</script>

<style scoped></style>
