import Vue from "vue";
import Router from "vue-router";
import Innringere from "@/views/Innringere";
import Callers from "@/views/Callers";
import Selvregistrerte from "@/views/Selvregistrerte";
import Tidspunkt from "@/views/Tidspunkt";
import SamlinkStatus from "@/views/SamlinkStatus";
import Info from "@/views/Info";
import Log from "@/views/Log";
import Statistikk from "@/views/Statistikk";
import Innstillinger from "@/views/Innstillinger";
import Viderekoblinger from "@/views/Viderekoblinger";
import WebsocketsTest from "@/views/WebsocketsTest";
import Retningslinjer from "@/views/Retningslinjer";

import ActiveCalls from "./views/ActiveCalls";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/info",
      name: "Info",
      component: Info,
    },
    {
      path: "/innstillinger",
      name: "Innstillinger",
      component: Innstillinger,
    },
    {
      path: "/viderekoblinger",
      name: "Viderekoblinger",
      component: Viderekoblinger,
    },
    {
      path: "/kalender",
      name: "Kalender",
      component: Tidspunkt,
    },
    {
      path: "/samlink_status",
      name: "SamlinkStatus",
      component: SamlinkStatus,
    },
    {
      path: "/activecalls",
      name: "ActiveCalls",
      component: ActiveCalls,
    },
    {
      path: "/log",
      name: "Log",
      component: Log,
    },
    {
      path: "/statistikk",
      name: "Statistikk",
      component: Statistikk,
    },
    {
      path: "/innringere_old",
      name: "InnringereOld",
      component: Innringere,
    },
    {
      path: "/innringere",
      name: "Innringere",
      component: Callers,
    },
    {
      path: "/selvregistrerte",
      name: "Selvregistrerte",
      component: Selvregistrerte,
    },
    {
      path: "/retningslinjer",
      name: "Retningslinjer",
      component: Retningslinjer,
    },
    {
      path: "/websockets",
      name: "Websockets",
      component: WebsocketsTest,
    },
  ],
});
