<template>
  <div>
    <HeaderBox header="Informasjon" helpkey="infopage" />
    <ConferencePasswordBox />
    <v-container fluid class="pa-0 mx-0 my-4">
      <v-row>
        <v-col cols="12" sm="7" md="8" lg="5" xl="5">
          <NewsList />
        </v-col>
        <v-col cols="12" sm="5" md="4" lg="3" xl="3">
          <SysAdmins />
          <ConferenceNumbers />
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="4" xl="4">
          <AdminList />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import ConferencePasswordBox from "@/components/ConferencePasswordBox";
import NewsList from "@/components/NewsList";
import SysAdmins from "@/components/SysAdmins";
import ConferenceNumbers from "@/components/ConferenceNumbers";
import AdminList from "@/components/AdminList";

export default {
  data() {
    return {
      adjustment: "",
    };
  },

  components: {
    HeaderBox,
    ConferencePasswordBox,
    NewsList,
    SysAdmins,
    ConferenceNumbers,
    AdminList,
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
