<template>
  <v-data-table
    :headers="headers"
    :items="selectedCallers"
    :search="search"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn-toggle
          v-model="active_callers_toggle"
          color="amber darken-4"
          dense
          group
          mandatory
        >
          <v-btn> Aktive </v-btn>
          <v-btn> Inaktive </v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialogMain" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Ny innringer
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.navn"
                      :rules="nameRules"
                      label="Innringer Navn"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="accessAlternatives"
                      v-model="editedItem.tilgang"
                      single-line
                      item-text="text"
                      item-value="text"
                      label="Tilgang"
                      :rules="[(v) => !!v || 'Må fylles ut']"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="8" md="8">
                    <vue-tel-input-vuetify
                      v-model="editedItem.telefon"
                      label="Telefonnummer"
                      @input="onPhoneInput"
                      v-bind="numberProps"
                    ></vue-tel-input-vuetify>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-checkbox
                      label="Internett"
                      v-model="editedItem.internettbruker"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-dialog
                      ref="dialogStartDate"
                      v-model="dialogStartDate"
                      :return-value.sync="startdate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.startdato"
                          label="Startdato"
                          :rules="[(v) => !!v || 'Må fylles ut']"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.startdato"
                        first-day-of-week="1"
                        locale="no-nb"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogStartDate = false"
                        >
                          Avbryt
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogStartDate.save(startdate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-dialog
                      ref="dialogEndDate"
                      v-model="dialogEndDate"
                      :return-value.sync="enddate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.sluttdato"
                          label="Sluttdato"
                          :rules="[(v) => !!v || 'Må fylles ut']"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.sluttdato"
                        first-day-of-week="1"
                        locale="no-nb"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="dialogEndDate = false"
                        >
                          Avbryt
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.dialogEndDate.save(enddate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      v-model="editedItem.kommentar"
                      label="Kommentar"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Avbryt </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Lagre </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-toolbar color="red">
            <v-toolbar-title class="white--text"
              >Slette innringer</v-toolbar-title
            >
          </v-toolbar>
          <v-card>
            <v-card-title class="text-h5"
              >Er du sikker på at du vil slette?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Avbryt</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Ja</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogError" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Kunne ikke lagre til databasen</v-card-title
            >
            <v-card-text>
              <p v-if="numberAlreadyInConferences">
                Et telefonnummer kan ikke registreres flere ganger i systemet.
                Nummeret er allerede registrert i følgende konferanse(r):
              </p>
              <p v-else>Har du fylt inn alle felt korrekt?</p>
              <ul>
                <li
                  v-for="conference in numberAlreadyInConferences"
                  v-bind:key="conference.id"
                >
                  {{ conference.konferanse }}
                </li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeError">Ok</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Søk"
        single-line
      >
      </v-text-field>
    </template>

    <template v-slot:[`item.telefon`]="{ item }">{{
      formatPhonenumber(item.telefon)
    }}</template>

    <template v-slot:[`item.internettbruker`]="{ item }"
      ><v-icon class="mr-2" color="teal" v-if="item.internettbruker"
        >done</v-icon
      >
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="mr-2" color="teal" @click="editItem(item)"> edit </v-icon>
      <v-icon color="pink" @click="deleteItem(item)"> delete </v-icon>
    </template>

    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import {
  createInnringer,
  readInnringere,
  updateInnringer,
  deleteInnringer,
} from '../../utils/backend-api';

import { formatNumber } from 'libphonenumber-js';

export default {
  data: () => ({
    dialogMain: false,
    dialogStartDate: false,
    dialogEndDate: false,
    dialogDelete: false,
    dialogError: false,
    search: '',
    active_callers_toggle: undefined,
    error: '',
    nameRules: [(v) => !!v || 'Navn må fylles ut'],
    startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    enddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    headers: [
      { text: 'Telefon', value: 'telefon', width: '145px' },
      { text: 'Navn', value: 'navn', width: '150px' },
      { text: 'Tilgang', value: 'tilgang' },
      { text: 'Startdato', value: 'startdato' },
      { text: 'Sluttdato', value: 'sluttdato' },
      { text: 'Internett', value: 'internettbruker' },
      { text: 'Kommentar', value: 'kommentar' },
      { text: 'Endre/Slett', value: 'actions', sortable: false },
    ],
    accessAlternatives: [
      { text: 'Lytter' },
      { text: 'Samlingshus' },
      { text: 'Telefonansvarlig' },
    ],
    callers: [],
    numberAlreadyInConferences: [],
    editedIndex: -1,
    editedItem: {
      telefon: '',
      navn: '',
      tilgang: '',
      startdato: '',
      sluttdato: '',
      internettbruker: false,
      kommentar: '',
    },
    defaultItem: {
      telefon: '',
      navn: '',
      tilgang: '',
      startdato: '',
      sluttdato: '',
      internettbruker: false,
      kommentar: '',
    },
    phone: {
      number: '',
      valid: false,
      country: undefined,
    },
    numberProps: {
      validCharactersOnly: true,
      placeholder: 'Velg land og skriv inn telefonnummer',
      preferredCountries: ['NO', 'SE', 'FI', 'DK'],
      errorMessages: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Ny innringer' : 'Endre innringer';
    },

    selectedCallers: function () {
      if (this.active_callers_toggle == 0) {
        // Active callers
        return this.callers.filter(function (caller) {
          var today = new Date().toISOString().slice(0, 10);
          var aktiv = caller.startdato <= today && caller.sluttdato >= today;
          return aktiv && !caller.selvregistrert;
        });
      } else {
        // Inactive callers
        return this.callers.filter(function (caller) {
          var today = new Date().toISOString().slice(0, 10);
          var aktiv = caller.startdato <= today && caller.sluttdato >= today;
          return !aktiv && !caller.selvregistrert;
        });
      }
    },
  },

  watch: {
    dialogMain(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    formatPhonenumber: function (value) {
      value = '+' + value;
      value = formatNumber(value, 'International');
      return value;
    },

    initialize() {
      this.getInnringere();
    },

    async getInnringere() {
      try {
        const response = await readInnringere();
        this.callers = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    editItem(item) {
      this.editedIndex = this.callers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogMain = true;
    },

    deleteItem(item) {
      this.editedIndex = this.callers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.callers.splice(this.editedIndex, 1);
      try {
        await deleteInnringer(this.editedItem.id);
        this.getInnringere();
      } catch (error) {
        console.log(error);
      }
      this.closeDelete();
    },

    close() {
      this.dialogMain = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeError() {
      this.dialogError = false;
      this.numberAlreadyInConferences = [];
    },

    async save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.callers[this.editedIndex], this.editedItem);
        try {
          await updateInnringer({
            id: this.editedItem.id,
            navn: this.editedItem.navn,
            telefon: this.phone.number,
            internettbruker: this.editedItem.internettbruker,
            tilgang: this.editedItem.tilgang,
            startdato: this.editedItem.startdato,
            sluttdato: this.editedItem.sluttdato,
            kommentar: this.editedItem.kommentar,
          });
          this.getInnringere();
        } catch (error) {
          this.error = error;
          this.numberAlreadyInConferences = error.response.data.conferences;
          this.dialogError = true;
        }
      } else {
        // this.callers.push(this.editedItem);
        try {
          await createInnringer({
            navn: this.editedItem.navn,
            telefon: this.phone.number,
            internettbruker: this.editedItem.internettbruker,
            tilgang: this.editedItem.tilgang,
            startdato: this.editedItem.startdato,
            sluttdato: this.editedItem.sluttdato,
            kommentar: this.editedItem.kommentar,
          });
          this.getInnringere();
        } catch (error) {
          this.error = error;
          this.numberAlreadyInConferences = error.response.data.conferences;
          this.dialogError = true;
        }
      }
      this.close();
    },
    onPhoneInput(formattedNumber, { number, valid, country }) {
      this.phone.number = valid
        ? number && number.e164.replace('+', '')
        : number;
      this.phone.valid = valid;
      this.phone.country = country && country.name;
      this.valid = valid;
      if (valid) {
        this.numberProps.errorMessages = '';
      } else {
        this.numberProps.errorMessages = 'Ugyldig telefonnummer.';
      }
    },
  },
};
</script>
