<template>
  <v-card class="mt-5" :disabled="samlink.version  === null" >
    <v-toolbar :color="isRented ? '#eee' : '#eee'" light short elevation="1">
      <v-toolbar-title>
        {{ samlink.name || "" }} ({{ samlink.key | removeLeadingCharacters }})
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <samlinkChangeNameForm
            v-if="samlink.owner === currentConference"
            :samlink="samlink"
          ></samlinkChangeNameForm>
          <v-list-item link @click="resetSamlinkState({ samlinkId: device })">
            <v-list-item-title>Reset denne enheten</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


    </v-toolbar>
    <v-card-subtitle v-if="isRented" class="rental-warning">
      <v-icon color="orange" x-large>warning</v-icon>
      <div>
        <p>
          Denne er lånt fra {{ samlink.owner }}. Dere deler kontrollen over den
          og lyd fra denne enheten sendes inn i konferansen
          {{ currentConference }}.
        </p>
      </div>
    </v-card-subtitle>
    <v-card-subtitle
      v-if="!isRented && samlink.rentals.length > 0"
      class="rental-warning"
    >
      <v-icon color="orange" x-large>warning</v-icon>
      <div>
        <p>
          Denne lånes av {{ samlink.rentals.join(" og ") }}. Dere deler
          kontrollen og lyd fra denne enheten sendes inn i konferansen til
          {{ samlink.rentals.join(" eller ") }}
        </p>

        <p>
          Mottak av lyd på denne enheten vil komme fra egen konferanse så lenge
          viderekobling ikke er bestilt.
        </p>
        <p>
          Det samme gjelder for telefon og internettlyttere, de vil ikke motta
          lyd fra denne samlinken så lenge det ikke er bestilt viderekobling.
          Altså tilbake fra konferansen {{ samlink.conference }} til konferansen
          {{ currentConference }}.
        </p>
      </div>
    </v-card-subtitle>
    <v-card-text v-if="samlink.version !== null">
      <SamLinkAliveness :secondsSinceReported="samlink.reported_state"/>
      <div>
        <div v-if="samlink.reported_state.state === 'send'">
          <div>
            Denne enheten sender lyd til konferansen {{ samlink.conference }}
          </div>
        </div>
        <div v-else-if="samlink.reported_state.state === 'receive'">
          <div>Denne enheten lytter</div>
        </div>
        <div v-else>
          <div class="grey--text">Ingen overføring</div>
        </div>
      </div>
    </v-card-text>
    <v-card-text v-if="samlink.version === null">Oppgradering kreves. Sørg for at enheten er på nett og ta kontakt med administrator.</v-card-text>
    <v-card-actions>
      <v-row>
        <v-btn
          :disabled="
            samlink.wanted_state === 'pending' ||
            (isRented && samlink.wanted_state === 'receive')
          "
          :loading="samlink.wanted_state === 'pending'"
          text
          color="orange"
          @click="stop()"
          v-show="samlink.wanted_state !== 'off'"
        >Stopp
          <v-icon dark right>stop</v-icon>
        </v-btn
        >
        <v-btn
          :disabled="samlink.wanted_state === 'pending'"
          :loading="samlink.wanted_state === 'pending'"
          text
          color="orange"
          @click="startRecord()"
          v-show="samlink.wanted_state === 'off'"
        >Send lyd fra denne enheten
          <v-icon dark right>mic</v-icon>
        </v-btn>
        <v-btn
          :disabled="samlink.wanted_state === 'pending'"
          :loading="samlink.wanted_state === 'pending'"
          :hidden="isRented"
          text
          color="orange"
          @click="startListen()"
          v-show="samlink.wanted_state === 'off'"
        >Lytt på denne enheten
          <v-icon dark right>play_arrow</v-icon>
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import store from "../store";

import samlinkChangeNameForm from "@/components/SamlinkChangeNameForm";
import SamLinkAliveness from "@/components/SamLinkAliveness";
import axios from 'axios'

export default {
  name: "SamLink",
  components: {
    SamLinkAliveness,
    samlinkChangeNameForm,
  },
  props: ["samlink", "device"],
  computed: {
    samlinkAliveness() {
      return (
        store.state.samlinkAliveness &&
        store.state.samlinkAliveness[this.device]
      );
    },
    currentConference() {
      return store.state.conference;
    },
    isRented({samlink}) {
      return store.state.conference !== samlink.owner;
    },
  },

  methods: {


    startRecord: function () {
      axios.put(`/api/v1/samlinks/${this.device}/`,{
        wanted_state: 'send'
      })
      this.recordButtonDisabled = true;
    },
    stop: function () {
      axios.put(`/api/v1/samlinks/${this.device}/`,{
        wanted_state: 'off'
      })
      this.stopButtonDisabled = true;
    },

    startListen: function () {
      axios.put(`/api/v1/samlinks/${this.device}/`,{
        wanted_state: 'receive'
      })
      this.listenButtonDisabled = true;
    },

  },

  filters: {
    removeLeadingCharacters: function (string) {
      //strip leading zeroes
      return string.replace(/^0+/, "");
    },
  },
};
</script>

<style scoped>
div {
  font-weight: normal;
}

div.rental-warning {
  background-color: #fff4ac;
  padding: 10px;

  font-size: 12pt;

  justify-content: left;
  align-items: center;

  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  div.rental-warning {
    flex-direction: column;
  }
}

div.rental-warning > * {
  text-align: center;
}
div.rental-warning > *:first-child {
  display: block;
  padding-right: 20px;
}

div.rental-warning > *:last-child {
  display: block;
}

p {
  padding-top: 10px;
}
</style>
