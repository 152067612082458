<template>
  <div>
    <v-btn color="blue lighten-2" dark v-on:click="websocketStopListen()">
      Websocket listen off
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    websocketStopListen() {
      // FIXME: not working
      delete this.$options.sockets.onmessage;
      console.log("Not listening (smth wrong).");
    },
  },
};
</script>
