<template>
  <v-card class="pa-2" tile>
    <v-card-title> Lokaladministratorer </v-card-title>
    <v-card-text>
      <v-simple-table :dense="true">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Konferanse</th>
              <th>Navn</th>
              <th>Telefon</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in localAdmins" :key="i.username">
              <td>{{ i.konferanse }}</td>
              <td>
                <a v-bind:href="'mailto:' + i.email"
                  >{{ i.first_name }} {{ i.last_name }}</a
                >
              </td>
              <td>
                {{ formatPhonenumber(i.phonenumber) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { getUsers } from "../../utils/backend-api.js";
import { formatNumber } from "libphonenumber-js";

export default {
  data() {
    return {
      localAdmins: [],
    };
  },
  methods: {
    async getLocalAdmins() {
      try {
        const response = await getUsers();
        this.localAdmins = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    formatPhonenumber: function (value) {
      return formatNumber("+" + value, "International");
    },
  },
  created() {
    this.getLocalAdmins();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
