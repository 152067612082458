<template>
  <div>
    <v-fab-transition>
      <v-btn
        v-model="fab"
        bottom
        color="primary"
        dark
        fab
        fixed
        right
        v-on:click="scanForDevices"
      >
        <v-icon>bluetooth</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">Konfigurere trådløsnett på SamLINK</span>
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-combobox
              v-model="selectedSSID"
              :items="ssids"
              :rules="[v => !!v || 'Vennligst velg et trådløsnett']"
              label="Trådløsnett"/>

            <v-text-field
              v-model="password"
              label="Passord"
              required
              type="password"
            ></v-text-field>
            <v-btn :disabled="selectedSSID === '' || password === ''" color="primary" @click="submit"> Lagre</v-btn>
            <v-btn @click="dialog=false">Avbryt</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SamlinkConfigureWlan",
  data: () => ({
    fab: true,
    dialog: false,
    valid: true,
    device: null,
    password: "",
    selectedSSID: "",
    currentSSID: "",
    ssids: [],
  }),
  methods: {
    readSettings() {
      this.service
        .getCharacteristic("76a46d65-4293-420c-b468-79ffb84fc001") // ssid
        .then((characteristic) => {
          return characteristic.readValue();
        })
        .then((value) => {
          var decoder = new TextDecoder();
          var ssid = decoder.decode(value);
          console.log("currentSSID", ssid);
          this.currentSSID = ssid;
        });

      this.service
        .getCharacteristic("76a46d65-4293-420c-b468-79ffb84fc003") // ip
        .then((characteristic) => {
          return characteristic.readValue();
        })
        .then((value) => {
          let ip =
            value.getUint8(0) +
            "." +
            value.getUint8(1) +
            "." +
            value.getUint8(2) +
            "." +
            value.getUint8(3);
          console.log("currentIP", ip);
          this.ip = ip;
        });

      this.service
        .getCharacteristic("76a46d65-4293-420c-b468-79ffb84fc001")
        .then((ssidChar) => {
          ssidChar.stopNotifications();
          ssidChar.startNotifications().then(() => {
            console.log("addEventListener");
            ssidChar.addEventListener(
              "characteristicvaluechanged",
              this.listSSID
            );
          });
        });
    },
    listSSID(event) {
      let value = event.target.value;
      let decoder = new TextDecoder();
      let ssid = decoder.decode(value);
      ssid = ssid.replace(/^"(.*)"$/, "$1");

      let ssids = this.ssids.slice();

      ssids.push(ssid);

      function uniq(a) {
        return a.sort().filter(function (item, pos, ary) {
          return !pos || item !== ary[pos - 1];
        });
      }

      this.ssids = uniq(ssids);
    },

    writeSettings() {
      if (
        this.device !== null &&
        this.password !== "" &&
        this.selectedSSID !== ""
      ) {
        this.service
          .getCharacteristic("76a46d65-4293-420c-b468-79ffb84fc001") // set ssid
          .then((ssidChar) => {
            let encoder = new TextEncoder();
            ssidChar.writeValue(encoder.encode(this.selectedSSID));
          })
          .then(() => {
            this.service
              .getCharacteristic("76a46d65-4293-420c-b468-79ffb84fc002") // set password
              .then((passwordChar) => {
                let encoder = new TextEncoder();
                passwordChar.writeValue(encoder.encode(this.password));
              });
          });
      }
      this.dialog = false
    },

    scanForDevices() {
      console.log("Requesting any Bluetooth Device...", navigator.bluetooth);
      navigator.bluetooth
        .requestDevice({
          filters: [{services: [0xfeaa]}],
          // acceptAllDevices: true,
          optionalServices: ["76a46d65-4293-420c-b468-79ffb84fc000"],
        })
        .then((device) => {
          console.log("Connecting to GATT Server...");
          this.device = device.name;
          return device.gatt.connect();
        })
        .then((server) => {
          console.log("Getting Device Information Service...");
          return server.getPrimaryService(
            "76a46d65-4293-420c-b468-79ffb84fc000"
          );
        })
        .then((service) => {
          this.service = service;
          console.log("Getting Device Information Characteristics...");
          this.readSettings();
          this.dialog = true;
        });
    },
    async submit() {
      this.writeSettings();
    },
  },
};
</script>
