<template>
  <v-chip v-if="counter > 60" class="ma-2" color="warning">
    <v-icon left> mdi-alert-circle </v-icon>
    Ikke kontakt
  </v-chip>
  <v-chip v-else-if="counter >= 0 && counter !== null" class="ma-2" color="success">
    <v-icon left> mdi-checkbox-marked-circle </v-icon>På nett
  </v-chip>
  <v-chip v-else class="ma-2" color="#ddd">
    <v-icon left> mdi-help-circle </v-icon>
    Ukjent status
  </v-chip>
</template>

<script>
export default  {
  name: "SamLinkAliveness",
  props: ["secondsSinceReported"],
  data: function () {
    return {
      timer: null,

      counter: this.secondsSinceReported
        ? this.secondsSinceReported.time
        : 0,
    };
  },

  watch: {
    secondsSinceReported: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val) {
        this.counter = val && val.time;
        // do your stuff
      },
    },
  },

  mounted: function () {
    this.timer = window.setInterval(() => {
        this.counter !== null && this.counter++;
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped></style>
