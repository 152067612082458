<template>
  <div v-if="isLoggedIn === false">
    <v-layout row justify-center>
      <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xs"
        persistent
        max-width="290"
      >
        <div v-if="systemDeactivated === true">
          <v-card>
            <v-toolbar dark color="red">
              <v-toolbar-title>Samlingstelefonen er nede</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              {{ systemDeactivatedMessage }}
            </v-card-text>
          </v-card>
        </div>

        <div v-else>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>Logg på</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-form @keyup.native.enter="login({ username, password })">
                <v-text-field
                  prepend-icon="person"
                  v-model="username"
                  name="login"
                  label="Brukernavn"
                  type="text"
                ></v-text-field>
                <v-text-field
                  prepend-icon="lock"
                  v-model="password"
                  name="password"
                  label="Passord"
                  autocomplete="password"
                  id="password"
                  type="password"
                ></v-text-field>
              </v-form>
              {{ loginError }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-on:click="submit" color="primary">Logg inn</v-btn>
              <v-btn
                text
                href="/accounts/password/reset/"
                target="_self"
                color="primary"
                >Nytt passord
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-dialog>
    </v-layout>
  </div>
  <div v-else>
    {{ isLoggedIn }}
    <slot />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: "",
      dialog: true,
      systemDeactivated: false,
      systemDeactivatedMessage: "Vi jobber med løsning. Prøv igjen senere.",
    };
  },
  methods: {
    ...mapActions(["login"]),
    submit() {
      this.login({ username: this.username, password: this.password });
      this.password = "";
    },
  },

  computed: mapState({
    isLoggedIn: (state) => state.isLoggedIn,
    loginError: (state) => state.loginError,
  }),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
