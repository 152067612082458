<template>
  <div>
    <HeaderBox header="Pågående samtaler" helpkey="pågående-samtaler" />
    <v-data-table :headers="headers" :items="calls" class="elevation-1">
      <template v-slot:no-data> Ingen pågående samtaler </template>
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.created | formatUnixDateTime }}</td>
          <td>{{ item.caller_id_name }}</td>
          <td>{{ item.caller_id_number }}</td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox";
import { formatNumber } from "libphonenumber-js";
import { getActiveCalls } from "../../utils/backend-api";

export default {
  name: "ActiveCalls",

  data() {
    return {
      headers: [
        { text: "Tidspunkt", value: "tid" },
        { text: "Navn", value: "navn" },
        { text: "Telefon", value: "telefon" },
      ],
      calls: [],
    };
  },
  components: {
    HeaderBox,
  },
  filters: {
    formatPhonenumber(value) {
      value = "+" + value;
      value = formatNumber(value, "International");
      return value;
    },
    formatUnixDateTime: function (unixtime) {
      const thisDateTime = new Date(unixtime * 1e3);

      const options = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      return new Intl.DateTimeFormat("nb-NO", options).format(thisDateTime);
    },
  },
  methods: {
    async getCalls() {
      try {
        const response = await getActiveCalls();
        if (response.data && response.data.rows !== "error") {
          this.calls = response.data.rows;
        } else {
          this.calls = [];
        }
        this.totalCalls = response.data.total_count;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getCalls();
    this.interval = setInterval(() => {
      this.getCalls();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped></style>
