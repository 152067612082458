<template>
  <div>
    <v-fab-transition>
      <v-btn
        color="primary"
        @click.stop="dialog = true"
        v-model="fab"
        dark
        fab
        fixed
        bottom
        right
      >
        <v-icon>edit</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">Endre personlige innstillinger</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              v-model="userinfoLocal.first_name"
              :rules="nameRules"
              :counter="30"
              label="Fornavn"
              required
            ></v-text-field>
            <v-text-field
              v-model="userinfoLocal.last_name"
              :rules="nameRules"
              :counter="30"
              label="Etternavn"
              required
            ></v-text-field>
            <v-text-field
              v-model="userinfoLocal.email"
              :rules="emailRules"
              label="Epost"
              required
            ></v-text-field>
            <vue-tel-input-vuetify
              v-model="userinfoLocal.phonenumber"
              label="Telefonnummer"
              mode="international"
              required
              @input="onPhoneInput"
              v-bind="numberProps"
            ></vue-tel-input-vuetify>
            <v-btn :disabled="!valid" @click="submit"> Lagre </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { updateInnstillinger } from "../../utils/backend-api";

export default {
  name: "InnstillingerChangeForm",
  props: ["userinfo"],
  data() {
    return {
      userinfoLocal: "",
      fab: true,
      dialog: false,
      valid: false,
      validNumber: false,
      first_name: "",
      last_name: "",

      phone: {
        number: "",
        valid: false,
        country: undefined,
      },
      nameRules: [
        (v) => !!v || "Navn på fylles inn",
        (v) => (v && v.length <= 30) || "Navn må være kortere enn 30 tegn",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "Epost-adresse må fylles inn",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "Ugyldig epost-adresse.",
      ],
      numberProps: {
        validCharactersOnly: true,
        placeholder: "Velg land og skriv inn telefonnummer",
        preferredCountries: ['NO', 'SE', 'FI', 'DK'],
        errorMessages: "",
      },
    };
  },

  methods: {
    onPhoneInput(formattedNumber, { number, valid, country }) {
      this.phone.number = valid
        ? number && number.e164.replace("+", "")
        : number;
      this.phone.valid = valid;
      this.validNumber = valid;
      this.phone.country = country && country.name;
      this.valid = valid;
      if (valid) {
        this.numberProps.errorMessages=""
      } else  {
        this.numberProps.errorMessages="Ugyldig telefonnummer."
      }
    },
    async submit() {
      if (this.$refs.form.validate() && this.phone.valid) {
        try {
          await updateInnstillinger({
            first_name: this.userinfoLocal.first_name,
            last_name: this.userinfoLocal.last_name,
            email: this.userinfoLocal.email,
            url: this.userinfoLocal.url,
            phonenumber: this.phone.number,
          });
          // Emitted signal can be catched by parent:
          this.$emit("innstillinger-updated");
          this.dialog = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
  created() {},
  updated() {
    this.userinfoLocal = this.userinfo;
  },
};
</script>
