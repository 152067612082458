<template>
  <v-card>
    <v-card-title class="headline grey lighten-2" primary-title>
      {{ header }}
    </v-card-title>
    <v-card>
      <v-card-text class="font-regular">
        <MarkdownViewer :text="guidelines" />
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import MarkdownViewer from "@/components/MarkdownViewer";
import { readHelpItem } from "../../utils/backend-api.js";

export default {
  data() {
    return {
      adjustment: "",
      header: "",
      guidelines: "",
    };
  },
  computed: {
    conference() {
      return this.$store.state.conference;
    },
    country_code() {
      return this.$store.state.country_code;
    },
  },

  components: {
    MarkdownViewer,
  },
  methods: {
    // return helpItem.tekst.replace("FORSAMLING", this.conference);

    getHelpItem(item) {
      if (this.country_code === "SE") {
        console.log("SWEDISH");
        this.header = "Riktlinjer för telefonmöten";
        return item.key === "guidelines-swedish";
      } else {
        console.log("NORWEGIAN");
        this.header = "Retningslinjer for telefonmøter";
        return item.key === "guidelines-norwegian";
      }
    },

    async getGuidelines() {
      if (this.country_code === "SE") {
        // SWEDISH
        this.header = "Riktlinjer för telefonmöten";
        try {
          const response = await readHelpItem("guidelines-swedish");
          this.guidelines = response.data["tekst"].replace(
            "FORSAMLING",
            this.conference
          );
        } catch (error) {
          // TODO: catch errors
        }
      } else {
        // NORWEGIAN
        this.header = "Retningslinjer for telefonmøter";
        try {
          const response = await readHelpItem("guidelines-norwegian");
          this.guidelines = response.data["tekst"].replace(
            "FORSAMLING",
            this.conference
          );
        } catch (error) {
          // TODO: catch errors
        }
      }
    },
  },
  mounted() {
    window.vm.$forceUpdate();
  },
  created() {
    this.getGuidelines();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
