import axios from "axios";
// import { getAccessToken } from "./auth";

// axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.xsrfCookieName = "csrftoken";
// axios.defaults.withCredentials = true;

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL || "";
// var token = getAccessToken();
// axios.defaults.headers.common["Authorization"] = "Token " + token;

export function getActiveCalls() {
  const url = `${BACKEND_URL}/api/v1/activecalls/`;
  return axios.get(url);
}

//
// KONFERANSER
//
export function readKonferanser() {
  const url = `${BACKEND_URL}/api/v1/konferanser?visible=True/`;
  return axios.get(url).then((response) => response.data);
}

export function readConferencePhones() {
  const url = `${BACKEND_URL}/api/v1/phonebook/`;
  return axios.get(url).then((response) => response.data);
}

//
// VIDEREKOBLINGER
//
export function readViderekoblinger() {
  const url = `${BACKEND_URL}/api/v1/viderekoblinger/`;
  return axios.get(url).then((response) => response.data);
}

export function createViderekobling(viderekobling) {
  const url = `${BACKEND_URL}/api/v1/viderekoblinger/`;
  return axios.post(url, viderekobling);
}

export function updateViderekobling(viderekobling) {
  const url = `${BACKEND_URL}/api/v1/viderekoblinger/${viderekobling.id}/`;
  return axios.patch(url, viderekobling);
}

export function deleteViderekobling(id) {
  const url = `${BACKEND_URL}/api/v1/viderekoblinger/${id}/`;
  return axios.delete(url);
}

export function readViderekoblingerFraAndre() {
  const url = `${BACKEND_URL}/api/v1/viderekoblinger-fra-andre/`;
  return axios.get(url).then((response) => response.data);
}

export function updateViderekoblingFraAndre(viderekobling) {
  const url = `${BACKEND_URL}/api/v1/viderekoblinger-fra-andre/${viderekobling.id}/`;
  return axios.patch(url, viderekobling);
}

//
// USERS
//
export function getUsers() {
  const url = `${BACKEND_URL}/api/v1/users/`;
  return axios.get(url);
}

export function isCurrentUser(user) {
  const username = window.$cookies.get("username");
  return user.username === username;
}

export function getUserInfo(userid) {
  const url = `${BACKEND_URL}/api/v1/users/${userid}/`;
  return axios.get(url).then((response) => response.data);
}

export function apiDummyLogRefresh(username) {
  const url = `${BACKEND_URL}/api/v1/reload/${username}/`;
  return axios.get(url).then((response) => response.data);
}

//
// INNSTILLINGER
//
export function updateInnstillinger(innstillinger) {
  const url = innstillinger.url;
  return axios.put(url, innstillinger);
}

//
// INNRINGERE
//
export function createInnringer(innringer) {
  const url = `${BACKEND_URL}/api/v1/innringere/`;
  return axios.post(url, innringer);
}

export function readInnringere() {
  const url = `${BACKEND_URL}/api/v1/innringere/`;
  return axios.get(url);
}

export function updateInnringer(innringer) {
  const url = `${BACKEND_URL}/api/v1/innringere/${innringer.id}/`;
  return axios.put(url, innringer);
}

export function deleteInnringer(id) {
  const url = `${BACKEND_URL}/api/v1/innringere/${id}/`;
  return axios.delete(url);
}

//
// SAMLINK
//
export function updateSamlinkName(samlink) {
  const url = `${BACKEND_URL}/api/v1/samlinks/${samlink.key}/`;
  const post = { name: samlink.name };
  return axios.put(url, post);
}

//
// INFO
//
export function readBlog() {
  const url = `${BACKEND_URL}/api/v1/blog/`;
  return axios.get(url);
}

//
// HELP
//
export function readHelpItem(key) {
  const url = `${BACKEND_URL}/api/v1/help/${key}/`;
  return axios.get(url);
}

//
// LOG
//
export function readLog() {
  const url = `${BACKEND_URL}/api/v1/logs/`;
  return axios.get(url);
}
//
// STATISTICS
//
export function readPhoneStats() {
  const url = `${BACKEND_URL}/api/v1/stats-phones/`;
  return axios.get(url);
}

export function readInternetStats() {
  const url = `${BACKEND_URL}/api/v1/stats-internet/`;
  return axios.get(url);
}
export function readPhoneMonthStats() {
  const url = `${BACKEND_URL}/api/v1/monthstats-phones/`;
  return axios.get(url);
}

export function readInternetMonthStats() {
  const url = `${BACKEND_URL}/api/v1/monthstats-internet/`;
  return axios.get(url);
}
